<template>
	<div>
		<div 
			class="fixed top-0 left-0 right-0 flex justify-center m-4"
			style="z-index: 1000;">
			<!-- flex items-center justify-center  -->
			
			<div class="kudo-animation bg-gray-darker rounded-lg px-12 py-6 text-white text-xl shadow-lg">
				{{ kudo.from.user.given_name + ' ' + kudo.from.user.family_name+' sendte deg ' }}
				<div class="rounded-full w-12 h-12 bg-white text-black inline-flex items-center justify-center ml-2">
					{{ symbol }}
				</div>
			</div>
			
			<!-- <pre>{{ kudo }}</pre> -->
		</div>
	</div>
</template>

<script>
export default {
	props: {
		// show: {
		// 	default: true,
		// },
	},
	
	computed:{
		kudo(){
			return this.$store.getters.getKudoNotice;
		},
		symbol(){
			if ( !this.kudo?.symbol ) {
				return null;
			}
			
			let symbol;
			
			switch ( this.kudo.symbol ) {
				case 'heart':
					symbol = '❤️';
					break;
				case 'thumbsUp':
				default:
					symbol = '👍🏼';
					break;
			}
			
			return symbol;
		},
	},
	
	data(){
		return {
			// loading: null,
		}
	},
	
	methods: {
		
	},
}
</script>



<style lang="scss">
	.kudo-animation {
		animation: kudo 5s ease-in-out forwards;
		z-index: 1000;
	}
	
	@keyframes kudo { 
		0% { 
			transform: scale(0); 
		}
		10% { 
			transform: scale(2); 
		} 
		20% { 
			transform: scale(.75); 
		} 
		90% { 
			transform: scale(1); 
			opacity: 1;
		} 
		100% { 
			transform: scale(5); 
			opacity: 0;
		} 
	}
</style>